import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from "./context";


const ButtonGroup = ({ ids, buttons, question_data, displayInfo, clickedRender, setClickedRender, disabled, setDisabled }) => {
    const tickSVG = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM1IDM1Ij4KICA8ZyBpZD0iR3JvdXBfNjA3OSIgZGF0YS1uYW1lPSJHcm91cCA2MDc5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjY0IC00MjUuNSkiPgogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV85OSIgZGF0YS1uYW1lPSJFbGxpcHNlIDk5IiBjeD0iMTcuNSIgY3k9IjE3LjUiIHI9IjE3LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY2NCA0MjUuNSkiIGZpbGw9IiMxYzhhODEiLz4KICAgIDxwYXRoIGlkPSJub3VuLXRpY2stNTE1MzQ1NF8xXyIgZGF0YS1uYW1lPSJub3VuLXRpY2stNTE1MzQ1NCAoMSkiIGQ9Ik0xOTcuNjQxLDE4NS40MjVoMGExLjI0NywxLjI0NywwLDAsMC0uODU3LjQzM2wtNy4zMTgsOC40OTUtMy40MzItMy41NDRoMGExLjI0OCwxLjI0OCwwLDAsMC0xLjc4NywxLjc0MWw0LjM3NCw0LjUxOGExLjI0NywxLjI0NywwLDAsMCwxLjg0MS0uMDU0bDguMjEtOS41MzdoMGExLjI0MSwxLjI0MSwwLDAsMC0xLjAzLTIuMDUzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg5LjYwNSAyNTEuNTY4KSIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+CiAgPC9nPgo8L3N2Zz4K"
    const crossSVG = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM1IDM1Ij4KICA8ZyBpZD0iR3JvdXBfNjA4MCIgZGF0YS1uYW1lPSJHcm91cCA2MDgwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzIxIC00MjUuNSkiPgogICAgPGcgaWQ9IkVsbGlwc2VfOTgiIGRhdGEtbmFtZT0iRWxsaXBzZSA5OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIxIDQyNS41KSIgZmlsbD0iI2Q2MWIyNiIgc3Ryb2tlPSIjZDYxYjI2IiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8Y2lyY2xlIGN4PSIxNy41IiBjeT0iMTcuNSIgcj0iMTcuNSIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxjaXJjbGUgY3g9IjE3LjUiIGN5PSIxNy41IiByPSIxNyIgZmlsbD0ibm9uZSIvPgogICAgPC9nPgogICAgPGcgaWQ9Ikdyb3VwXzYwMDEiIGRhdGEtbmFtZT0iR3JvdXAgNjAwMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtOSkiPgogICAgICA8bGluZSBpZD0iTGluZV8xOTYiIGRhdGEtbmFtZT0iTGluZSAxOTYiIHkyPSIxMy44NjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM0My40MDQgNDQ2Ljc1NCkgcm90YXRlKDQ1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMi41Ii8+CiAgICAgIDxsaW5lIGlkPSJMaW5lXzE5NyIgZGF0YS1uYW1lPSJMaW5lIDE5NyIgeDE9IjAuMDAxIiB5MT0iMTMuODY4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNDMuNDA0IDQ1Ni41Nikgcm90YXRlKDEzNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIuNSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
    
    const isMounted = useRef(false);

    const { correctAnswer, setCorrectAnswer } = useContext(Context);

    const [clicked, setClicked] = useState(-1)

    const noAnswer = "rounded-full text-black border border-border-grey text-xl w-72 sm:w-80 py-2 hover:border hover:border-hover-grey hover:bg-fill-grey"
    const correctStyle = "rounded-full text-correct-green bg-correct-green bg-opacity-10 border border-correct-green text-xl w-72 sm:w-80 py-2"
    const incorrectStyle = "rounded-full text-incorrect-red bg-incorrect-red bg-opacity-10 border border-incorrect-red text-xl w-72 sm:w-80 py-2"

    const [buttonStyle, setButtonStyle] = useState(noAnswer)

    let correctAnswerGiven

    const handleClick = (id, selectedAnswer) => {
        setClicked(id)
        displayInfo()
        setDisabled(true)

        setStyling(selectedAnswer)
    }

    const setStyling = (selectedAnswer) => {
        if (selectedAnswer === question_data["correct_answer"]) {
            correctAnswerGiven = true
            setButtonStyle(correctStyle)
            document.getElementById("tick").style.visibility = "visible"

        }
        else {
            correctAnswerGiven = false
            setButtonStyle(incorrectStyle)
            document.getElementById("correct_answer").className = correctStyle
            document.getElementById("tick").style.visibility = "visible"
        }

        setCorrectAnswer(correctAnswerGiven)
    }


    useEffect(() => {
        if (clickedRender === true) {
            setClicked(-1)
            setClickedRender(false)
        }
    }, [setStyling]);   
    

    return (
        <>
            {buttons.map((buttonLabel, i, id) => (
                <button 
                    key={i}
                    id={ids[i]}
                    name={buttonLabel} 
                    disabled={disabled} 
                    onClick={() => handleClick(i, buttonLabel)} 
                    className={i === clicked ? buttonStyle : noAnswer}
                    >
                    {ids[i] === "correct_answer" ?
                    <div className="flex flex-row text-center">
                        <img id="tick" src={tickSVG} alt="Correct tick" className="w-7 ml-3 leading-6" style={{visibility: "hidden"}} /> 
                        <p id="answer_button" className="w-3/4 font-bold">{buttonLabel}</p>
                    </div>
                    :
                    (i === clicked ?
                    <div className="flex flex-row">
                        <img src={crossSVG} alt="Incorrect cross" className="w-7 ml-3 leading-6" />
                        <p id="answer_button" className="w-3/4 font-bold">{buttonLabel}</p>
                    </div>
                    :
                    <div className="flex flex-row">
                        <img src={crossSVG} alt="Incorrect cross" className="w-7 ml-3 leading-6" style={{visibility: "hidden"}} />
                        <p id="answer_button" className="w-3/4 font-bold">{buttonLabel}</p>
                    </div>)
                    }
                </button>
            ))}
        </>
    )
}

export default ButtonGroup