import React from 'react';

export default function Question(props) {
    return (
        <div className="text-center grid content-center place-items-center">
            <p className="p-1 text-xl font-bold">Question {props.number}</p>
            <h2 className="text-red-600 pt-1.5 pb-9 text-3xl w-4/5 2xl:w-3/5 uppercase">{props.question}</h2>
        </div>
    )
}
