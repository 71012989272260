import React, { useState, createContext } from "react";
  
export const Context = createContext();
export const ContextProvider = ({ children }) => {
    const [correctAnswer, setCorrectAnswer] = useState(null);

  
    return (
        <Context.Provider value={{ correctAnswer, setCorrectAnswer }}>
            {children}
        </Context.Provider>
    );
};