import React from 'react';

export default function QuizInfo(data) {
    console.log(data)
    return (
        <div className="mt-8">
            <p className="text-base">{data.data["question_info"]}</p>
            {data.data["question_link"] ?
            <p className="font-bold">To find out more <a href={data.data["question_link"]} target="_blank" rel="noreferrer" className="text-red-600 underline text-base">{data.data["question_title"] ? data.data["question_title"] : "click here"}</a></p>
            : null}
        </div>
    )
}
